<template>
  <div class="leading-normal" v-if="render">
    <div class="flex flex-col gap-2">
      <div class="flex flex-row text-s items-center gap-3">
        <!-- Full description -->
        <span
          v-show="!isDescriptionHidden"
          v-html="userDataEdit.descriptionEdit.description"
          class="text-base leading-normal description-text"
        >
        </span>
        <!-- Description excerpt -->
        <span
          v-show="isDescriptionHidden"
          v-html="getDescriptionExcerpt()"
          class="text-base leading-normal description-text text-grayedDark"
        ></span>
      </div>
      <div class="w-full flex flex-row justify-end">
        <template v-if="profileData.descriptionEdit.description" class="w-full flex flex-row justify-end mt-1.5 lg:mt-3">
          <template v-if="!isDescriptionHidden">
            <Button variant="link"
              class="text-s inline-flex hover:opacity-50 cursor-pointer items-center"
              v-bind:class="{
                'opacity-100 cursor-pointer hover:opacity-70': profileData.descriptionEdit.description,
                'opacity-70 cursor-default': !profileData.descriptionEdit.description,
              }"
              @clicked="collapseCurrentDescription(true)"
            >
              <base-icon class="mr-2" name="arrowsCollapse"></base-icon>
              {{ trans('BUTTON:ProfileEditDescriptionCollapse') }}
            </Button>
          </template>
          <template v-else>
            <Button variant="link"
              :disabled="!isDescriptionExcerpt()"
              class="text-s inline-flex hover:opacity-70 cursor-pointer items-center"
              @clicked="collapseCurrentDescription(false)"
            >
              <base-icon class="mr-2" name="arrowsExpand"></base-icon>
              {{ trans('BUTTON:ProfileEditDescriptionExpand') }}
            </Button>
          </template>
        </template>
      </div>
      <template v-if="userDataEdit.descriptionEdit.description">
        <hr/>
      </template>
      <div class="flex flex-col lg:flex-row items-center gap-3 mb-2"
        v-if="userDataEdit.descriptionEdit.description"
      >
        <div class="w-full flex justify-start">
          <Button
            v-show="userDataEdit.descriptionEdit.description"
            variant="link"
            :disabled="!userDataEdit.descriptionEdit.description
              || userDataEdit.descriptionEdit.description === userDataEdit.descriptionEdit.descriptionNew"
            class="text-s inline-flex hover:opacity-50 cursor-pointer items-center"
            @clicked="populateDescription()"
          >
            <base-icon class="mr-2" name="arrowsPopulate"></base-icon>
            {{ trans('BUTTON:ProfileEditDescriptionPopulate') }}
          </Button>
        </div>
        <div class="w-full flex justify-start lg:justify-end">
          <Button
            variant="link"
            :disabled="!userDataEdit.descriptionEdit.descriptionNew"
            class="text-s inline-flex hover:opacity-50 cursor-pointer items-center"
            @clicked="emptyDescription()"
          >
            <base-icon class="mr-2" name="reset"></base-icon>
            {{ trans('BUTTON:ProfileEditDescriptionEmpty') }}
          </Button>
        </div>
      </div>
      <div :class="{'-mt-4':!userDataEdit.descriptionEdit.description}">
        <div class="trix-modal-description">
          <VueTrix
            v-model="userDataEdit.descriptionEdit.descriptionNew" type="text"
            ref="descriptionForm"
            inputName="description"
            :placeholder="trans('PLACEHOLDER:DescriptionEdit')"
            class="block w-full outline-none focus:text-gray-700 rounded border-none profile-desc px-0 py-0"
            name="message" autocomplete="off"
          />
          <span v-if="checkIsLengthValid" class="text-s text-badgeError">
            {{ trans('LABEL:DescriptionLengthValidator') }}&nbsp;({{ stringLength(userDataEdit.descriptionEdit.descriptionNew) + ' / ' + configData.userDescriptionLimitMinCHR + ' ' + trans('LABEL:letters') }})
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectField from "@/utils/crud/components/field-types/Select";
import {mapActions, mapGetters, mapMutations} from "vuex";
import BaseIcon from "@/utils/icons/BaseIcon";
import Button from "@/utils/crud/components/Button";
import {isEmpty, removeBrTags, stringLength, stripTags} from "@/common/helpers/utils";


export default {
  name: "DescriptionForm",
  components: {Button, SelectField, BaseIcon},
  props: {
    profileData: Object,
    sendRequest: Boolean,
    sendConfirmed: Boolean,
	  newDescriptionPending: Boolean,
  },
  data() {
    return {
      userDataEdit: {
        descriptionEdit: {
          description: null,
          descriptionNew: '',
          descriptionStatus: null,
          descriptionNewStatus: null,
        }
      },
      render: false,
      isDescriptionHidden: true,
      descriptionExcerptLength: 250,
      stripTags: stripTags,
      stringLength: stringLength,
	    trixEditor: null,
    }
  },
  watch: {
    userDataEdit: {
      deep: true,
      handler() {
        this.render = true;
      }
    },
    sendRequest(val) {
      if(val) {
        if(!this.userDataEdit.descriptionEdit.descriptionNew) {
          this.openEmptyDescriptionModal(true);
        } else {

          this.setSendRequestStatus(false);
          this.sendDescriptionEditRequest({
            description_new: removeBrTags(this.userDataEdit.descriptionEdit.descriptionNew),
          })
        }
      }
    },
    sendConfirmed(val) {
	    if (val) {
		    this.setSendConfirmed(false);
		    this.setSendRequestStatus(false);
		    let emptyDescription = isEmpty(this.userDataEdit.descriptionEdit.descriptionNew);
		    let description = emptyDescription ? null : removeBrTags(this.userDataEdit.descriptionEdit.descriptionNew);
		    this.sendDescriptionEditRequest({
			    description_new: description,
			    description_empty: emptyDescription,
		    })
	    }
    }
  },
  computed: {
    ...mapGetters('dictionaries', ['locationsData']),
	  ...mapGetters('account', ['userData']),
    ...mapGetters('lang', ['configData']),

	  checkIsLengthValid() {
			let checkLength = !!(this.userDataEdit.descriptionEdit.descriptionNew && stringLength(this.userDataEdit.descriptionEdit.descriptionNew) < this.configData.userDescriptionLimitMinCHR);
			let descriptionEmpty = isEmpty(this.userDataEdit.descriptionEdit.descriptionNew);
			let disableButton = checkLength && !descriptionEmpty;
			this.$emit('disabledConfirm', disableButton);
		  return disableButton;
	  }
  },
  methods: {
    ...mapMutations('profile', ['setSendRequestStatus', 'openEmptyDescriptionModal', 'setSendConfirmed']),
    ...mapActions('profile', ['sendEditRequest', 'sendDescriptionEditRequest']),
    ...mapActions('dictionaries', ['fetchDescriptionStatuses']),
    collapseCurrentDescription(value) {
      this.isDescriptionHidden = value;
    },
    emptyDescription() {
      this.userDataEdit.descriptionEdit.descriptionNew = null;
	    this.trixEditor.loadHTML(this.userDataEdit.descriptionEdit.descriptionNew);
    },
    populateDescription() {
      this.userDataEdit.descriptionEdit.descriptionNew = this.userDataEdit.descriptionEdit.description;
	    this.trixEditor.loadHTML(this.userDataEdit.descriptionEdit.descriptionNew)
    },
    getDescriptionExcerpt() {
      if(this.userDataEdit.descriptionEdit.description) {
        //if (stripTags(this.userDataEdit.descriptionEdit.description).length > this.configData.userDescriptionLimitMinCHR-3) {
        //return stripTags(this.userDataEdit.descriptionEdit.description).substring(0, this.configData.userDescriptionLimitMinCHR - 3) + '...';
        if (stripTags(this.userDataEdit.descriptionEdit.description).length > this.descriptionExcerptLength-3) {
          return this.userDataEdit.descriptionEdit.description.substring(0, this.descriptionExcerptLength-3) + '...';
        } else {
          return this.userDataEdit.descriptionEdit.description
        }
      }
      return ''
    },
    isDescriptionExcerpt() {
      if (this.userDataEdit.descriptionEdit.description) {
        //return stripTags(this.userDataEdit.descriptionEdit.description).length > this.configData.userDescriptionLimitMinCHR-3
        return stripTags(this.userDataEdit.descriptionEdit.description).length > this.descriptionExcerptLength-3
      }
      return false
    }
  },

  mounted() {
    this.userDataEdit.descriptionEdit = this.profileData.descriptionEdit;
		this.$nextTick(() => {
			this.trixEditor = this.$refs.descriptionForm.$refs.trix.editor;
		})
  },
	destroyed() {

	}
}
</script>

<style lang="scss">
.trix-modal-description {
  .trix-content {
    height: 240px;
    max-height: 450px !important;
    overflow-y: auto !important;
    background: theme('colors.grayedLight') !important;
  }
}

.profile-desc {

  .trix-button--icon-code {
    display: none;
  }

  .trix-button-group--file-tools {
    display: none;
  }
}
</style>